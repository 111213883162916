<template>

    <div class="work" :style="'background-image: url(\''+iSrc+'\')'" @click="linkto(nome)">
        <div class="tag">{{tag}}</div>
    </div>

</template>

<script>
import {i18n} from '@/lenguages/i18n';
export default {
    props:['iSrc','nome','work'],
    data() {
        return {
            opened:false,
            tag:''
        }
    },methods: {
        linkto(nome){
            if(i18n.locale=='it'){
                this.$router.push('/IT/lavoro/'+nome).catch(() =>{});    
            }else{
                this.$router.push('/EN/lavoro/'+nome).catch(() =>{}); 
            }            
        }
    },
    mounted(){
        if(this.work.tags.includes("platform")){
            this.tag = 'Piattaforma'
            this.tag = i18n.locale=='it'?'Piattaforma':'Platform'
        }else if(this.work.tags.includes("3D")){
            this.tag = '3D'
        }else if(this.work.tags.includes("website")){
            this.tag = 'Sito Web'
            this.tag = i18n.locale=='it'?'Sito Web':'Web Site'
        }
    }
}
</script>

<style lang="scss" scoped>
.work{
    max-width: 300px;
    min-width: 200px;
    width: 100%;
    background: white;
    color: white;
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    flex: 1 0 auto;
    height:auto;
    transition-duration: 0.2s;
    cursor: pointer;
    background-size: cover;
    &:hover{
        transition-duration: 0.2s;
        transform: scale(1.05);
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    }
    &:before {
        content:'';
        float:left;
        padding-top:100%;
    }
    .tag{
        text-align: right;
        font-size: 10pt;
        border-radius: 10px;
        padding: 2px 10px;
        background-color: #fff;
        color: black;
        width: max-content;
        margin-left: auto;
        margin-top: 10px;
        margin-right: 10px;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }   
}

</style>