<template>
  <div id="app">
    <Navbar/>
    <router-view/>
  </div>
</template>

<script>
import Navbar from './components/navbar.vue'
import {i18n} from '@/lenguages/i18n';
export default {
  components:{Navbar},
  methods:{
    appHeight(){
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }
  },
  mounted(){
    window.addEventListener('resize', this.appHeight);
    this.appHeight();
    if(this.$route.params.l){
      i18n.locale = this.$route.params.l=='EN'?'en':'it';
    }
    
  }
}
</script>

<style lang="scss" scoped>
@import url('./assets/scss/main.scss');
</style>
