<template>
    <div id="progressbar">
        <div class="name">{{name}}</div>
        <div class="progress" :val="(val/10)+' / 10'">
            <div class="value" :style="'width: '+val+'%'"></div>
        </div>
        
    </div>
</template>

<script>
export default {
    props:['val','name']
}
</script>

<style lang="scss" scoped>
$baseHeight: 35px;
$fontSize: 15pt;
$percFSize:10pt;
$innerColor: var(--mainTXT);
    #progressbar{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        column-gap: 10px;
        color: $innerColor;
        @media screen and (max-width: 370px){
            flex-flow: column;
            margin-bottom: 10px;
        }
        &:hover{
            color: var(--linkColor);
            .progress{
                border: 2px solid var(--linkColor);
                .value{
                    background-color:  var(--linkColor);
                    height: 100%;
                }
            }
        }
    }
    .name{
        font-size: $fontSize;
        line-height: $baseHeight;
        @media screen and (max-width: 560px){
            font-size: 12pt;
            margin-top: -6px;
        }
        @media screen and (max-width: 370px){
            text-align: center;
            line-height: 15px;
            margin-bottom: 3px;
        }
    }
    .progress{
        border: 2px solid $innerColor;
        height: $baseHeight;
        border-radius: 4px;position: relative;
        margin-bottom: 10px;
        width: 200px;
        @media screen and (max-width: 560px){
            width: 150px;
            height: 25px;
        }
        @media screen and (max-width: 370px){
            width: 200px;
        }
        &::after{
            content: attr(val);
            position:absolute;
            right: 3px;top:0px;
            line-height: $baseHeight;
            font-size: $percFSize;
            mix-blend-mode: difference;
            @media screen and (max-width: 560px){
                font-size: 9pt;
                top: -7px;
            }
        }
        .value{
            background-color: $innerColor;
            height: 100%;
        }
    }
</style>