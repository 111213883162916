<template>
    <div id="chisono">
        <div class="container">
            <div class="sectionTitle unselectable moreTop">{{$t('ChiSono')}}</div>
            <div class="flexxed">
                <div class="left">
                    <h1 class="unselectable">{{$t('Chi')}} <br>{{$t('Sono')}}</h1> 
                    <div class="foto"><img :src="require('../assets/imgs/me.jpg')" alt=""></div>   
                </div>
                <div class="right">
                    <div><span v-html="$t('chisono1')"></span> {{age}} <span v-html="$t('chisono2')"></span></div>
                    
                </div>
                   
            </div>
               
        </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            age:25,
        }
    },
    methods: {
        calculateAge(birthday) { // birthday is a date
            var ageDifMs = Date.now() - birthday;
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        }
    },
    mounted() {
        var byrth = new Date('02/05/1997');
        this.age = this.calculateAge(byrth);
    },
}
</script>

<style lang="scss" scoped>
.moreTop{
    top:30px;
}
#chisono{
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .container{
        max-width: 800px;
        width: 100%;
        
    }
    .flexxed{
        display: flex;
        column-gap: 20px;
        padding: 10px;
        @media screen and (max-width: 930px){
          flex-flow: column;
        }
        .left{
            @media screen and (max-width: 930px){
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: row;
                h1{
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 370px){
                        font-size: 35pt;
                        line-height: 40px;
                    }
                }
            }
            @media screen and (max-width: 490px){
                flex-flow: column;
            }
            
            
        }
        .foto{
            width: 280px;
            padding: 20px;
            img{
                width: 100%;
                border-radius: 10px;
            }
        }
        .right{
            display: flex;justify-content: center;align-items: center;
        }
    }
    h1{
        font-size: 60pt;
        font-weight: 600;
        line-height: 60px;
        border-left: 2px solid white;
        padding-left: 10px;
    }
}
</style>