import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {messagesEN} from './en'
import {messagesIT} from './it'
Vue.use(VueI18n);

const messages = {
    'en': messagesEN,
    'it': messagesIT
  };
export const i18n = new VueI18n({
    locale: 'it', // set locale
    fallbackLocale: 'it', // set fallback locale
    messages, // set locale messages
});