import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

import {i18n} from '@/lenguages/i18n';

import MenuIcon from 'vue-material-design-icons/Menu.vue';
import SchoolIcon from 'vue-material-design-icons/School.vue';
import WorkIcon from 'vue-material-design-icons/Briefcase.vue';
import InfoIcon from 'vue-material-design-icons/Information.vue';
import AboutIcon from 'vue-material-design-icons/ClipboardAccount.vue';
import CodeIcon from 'vue-material-design-icons/CodeNotEqualVariant.vue';

//ICONS
Vue.component('menu-icon', MenuIcon);
Vue.component('education-icon', SchoolIcon);
Vue.component('work-icon', WorkIcon);
Vue.component('info-icon', InfoIcon);
Vue.component('about-icon', AboutIcon);
Vue.component('code-icon', CodeIcon);

Vue.config.productionTip = false

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
