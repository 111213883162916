<template>
  <div class="home">
    <div class="main">
      <div class="background">
        <AnimatedBG/>
      </div>
      <div class="testo unselectable">
        <div class="firstRow">
          <p v-if="$t('leng')=='EN'"><span>H</span><span>i</span><span>!</span></p>
          <p v-if="$t('leng')=='IT'"><span>C</span><span>i</span><span>a</span><span>o</span><span>!</span></p>
        </div>
        <div class="secondRow">
          <div class="flexScritta">
            <div v-if="$t('leng')=='EN'" class="smallt">
              I'm
            </div>
            <div v-if="$t('leng')=='IT'" class="smallt">
              Sono
            </div>
            <div><span class="spaceBack colored">A</span>bramo</div>
          </div>
        </div>
      </div>
    </div>
    <AnimateScrool/>
    <div ref="chi-sono"><ChiSono/></div>
    <div ref="competenze"><Skills/></div>
    <div ref="formazione"><Formazione/></div>
    <div ref="lavori"><Lavori/></div>
    <div ref="contatti"><Contatti/></div>
  </div>
</template>

<script>
import AnimatedBG from '../components/animatedBG.vue'
import AnimateScrool from '../components/scrollAnimation.vue'
import ChiSono from '../components/ChiSono.vue'
import Skills from '../components/Skills.vue'
import Formazione from '../components/Formazione.vue'
import Lavori from '../components/Lavori.vue'
import Contatti from '../components/Contatti.vue'
import {i18n} from '@/lenguages/i18n';
export default {
  name: 'Home',
  data() {
    return {
      localLeng: i18n.locale,
    }
  },
  components: {
    AnimatedBG,
    AnimateScrool,
    ChiSono,
    Skills,
    Formazione,
    Lavori,
    Contatti
  },
  watch:{
    $route() {
      this.checkParms();
    },
  },
  methods:{
    checkParms(){
      if(this.$route.params.r){
        var element = this.$refs[this.$route.params.r];
        var top = element.offsetTop;
        window.scrollTo({ top: top, behavior: 'smooth' });  
      }else{
        window.scrollTo({ top: 0, behavior: 'smooth' }); 
      }
    },
  },
  mounted(){
    this.checkParms();    
  }
}
</script>

<style lang="scss" scoped>
.home{
  padding-left: var(--navWidth);
}
.main{
  height: 100vh;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .testo{
    z-index: 3;
    .firstRow{
      font-size: 120pt;
      font-weight: 700;
      line-height: 120px;
      @media screen and (max-width: 835px){
        text-align: center;
      }
      @media screen and (max-width: 510px){
        font-size: 100pt;
        line-height: 100px;
      }
      @media screen and (max-width: 410px){
        font-size: 80pt;
        line-height: 80px;
      }
    }
    .secondRow{
      font-size: 100pt;
      font-weight: 500;
      line-height: 100px;
      margin-top: 20px;
      @media screen and (max-width: 620px){
        font-size: 80pt;
        line-height: 80px;
      }
      @media screen and (max-width: 510px){
        font-size: 60pt;
        line-height: 60px;
      }
      @media screen and (max-width: 410px){
        font-size: 40pt;
        line-height: 40px;
      }
      .flexScritta{
        display: flex;
        @media screen and (max-width: 835px){
          flex-flow: column;
        }
      }
      .smallt{
        font-size: 70pt;
        text-align: center;
        @media screen and (max-width: 835px){
          line-height: 70px;
        }
        @media screen and (max-width: 620px){
          font-size: 50pt;
          line-height: 50px;
        }
        @media screen and (max-width: 510px){
          font-size: 30pt;
          line-height: 30px;
        }
        @media screen and (max-width: 410px){
          font-size: 20pt;
          line-height: 20px;
        }
      }
    }
    .spaceBack{
      margin-left: 40px;
      @media screen and (max-width: 835px){
          margin-left: 0px;
      }
    }
    .colored{
      color: rgb(236, 63, 63);
      font-weight: 700;
      position: relative;
      &::before{
        content: 'A';
        position: absolute;
        left:5px;
        top: 50%;
        transform: translateY(-47%);
        color: var(--linkColor);
      }
    }
  }
}
.background{
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1;
  display: flex;
  left:0;top:0;
}
</style>
