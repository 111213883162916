<template>
    <div id="lavori">
        <div class="sectionTitle unselectable">{{$t('Lavori')}}</div>
        <div class="title unselectable" v-if="$t('leng')=='IT'"><span let="L" class="mrL">L</span>AVORI</div>
        <div class="title unselectable" v-if="$t('leng')=='EN'"><span let="W" class="mrW">W</span>orks</div>
        <div class="works">
            <Work v-for="(work,index) in Object.entries(works).slice(0, 8)" :key="index" :work="work[1]" :nome="work[0]" :iSrc="require('../assets/works'+work[1].mainFoto)" />    
            <div class="more"><router-link :to="'/'+$t('leng')+'/allWorks'">{{$t('leng')=='IT'?'Visualizza altri':'See More'}}</router-link></div>
        </div>
        
        
    </div>
</template>

<script>
import Work from './Work.vue'
import works from '../utils/works.json'
export default {
    components:{Work},
    data() {
        return {
            currentWorkOpened: {},
            works:works,
        }
    }
}
</script>



<style lang="scss" scoped>
    .mrW{
        margin-right: -8px;
    }
    .mrL{
        margin-right: 10px;
    }
    #lavori{
        position: relative;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        .more{
            width: 100%;
            margin-top: 10px;
            text-align: right;
            padding-right: 10px;
            a{
                text-decoration: none;
            }
        }
        .title{
            font-size: 50pt;
            font-weight: 700;
            span{
                font-size: 80pt;
                color: rgb(236, 63, 63);
                position: relative;
                &::before{
                    content: attr(let);
                    position: absolute;
                    left:2px;
                    top:-2px;
                    color: var(--linkColor);
                }
            }
        }
        .works{
            max-width: 1200px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center
        }
    }
    .opened{
        width: 100%;
        height: 100vh;
        position: fixed;
        top:0;
        left:0;
        background-color: rgba(0, 0, 0, 0.253);
        backdrop-filter: blur(10px);
        cursor: pointer;
        z-index: 4;
    }
    .containerOpend{
        height: 100vh;
        position: fixed;
        top:0;
        left:var(--navWidth);
        width: calc(100% - var(--navWidth));
        z-index: 5;
        cursor: pointer;
    }
    .contentOpened{
        background-color: var(--mainBGDarker);
        max-width: 1200px;
        width: calc(100% - 20px);
        position: absolute;
        top:50%;left:50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 10px;
        display: flex;
        padding: 20px;
        column-gap: 20px;
        border: 2px solid rgba(255, 255, 255, 0.418);
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 845px){
            flex-flow: column-reverse;
            row-gap: 20px;
        }
        .close{
            width: max-content;
            color: rgb(241, 241, 241);
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
            transition-duration: 0.2s;
            border-radius: 2px;
            font-size: 20pt;
            line-height: 25px;
            &:hover{
                color: rgb(241, 241, 241);
                font-size: 25pt;
                transition-duration: 0.2s;
                transform: rotate(180deg);
            }
        }
        .left{
            max-width: 60%;
            @media screen and (max-width: 845px){
                max-width: 100%;
            }
            img{
                width: 100%;
                max-width: 600px;
                border-radius: 10px;
            }
        }
        .right{
            max-width: 40%;
            @media screen and (max-width: 845px){
                max-width: 100%;
            }
            .nome{
                font-size: 30pt;
                font-weight: 700;
                line-height: 30px;
            }
            .link{
                font-size: 10pt;
                margin-bottom: 20px;
                a{
                    text-decoration: none;
                }
            }
            .struttura{
                border: 1px solid white;
                border-radius: 3px;
                margin-top: 10px;
                position: relative;
                font-size: 9pt;
                padding:10px 5px 5px 5px;
                margin-bottom: 20px;
                .fieldTxt{
                    position:absolute;
                    top:-10px;left:50%;
                    font-size: 10pt;
                    transform: translateX(-50%);
                    background-color: var(--mainBGDarker);
                    padding: 0px 10px;
                }
            }
        }
    }
</style>