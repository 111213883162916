<template>
    <div id="skills">
        <div class="sectionTitle unselectable moreTop">{{$t('Abilità')}}</div>
        <div class="container">
            <div class="title">{{$t('Comp1')}}<br>{{$t('Inf1')}}</div>
            <div class="flexxed">
                <div class="left">
                    <ProgressBar name="HTML & CSS" val='95'/>
                    <ProgressBar name="Vue.js" val='92'/>
                    <ProgressBar name="JS" val='89'/>
                    <ProgressBar name="NODE.js" val='85'/>
                    <ProgressBar name="Photoshop" val='85'/>
                    <ProgressBar name="NoSQL" val='83'/>
                    <ProgressBar name="SQL" val='80'/>
                    <ProgressBar name="C#" val='80'/>
                    <ProgressBar name="PLC/Automation" val='80'/>
                    <ProgressBar name="JAVA" val='75'/>
                    <ProgressBar name="Blender" val='70'/>
                </div>
                <div class="right">
                    <ProgressBar name="Git" val='70'/>
                    <ProgressBar name="PHP" val='65'/> 
                    <ProgressBar name="Electron.js" val='64'/>
                    <ProgressBar name="Terraform" val='60'/>
                    <ProgressBar name="AWS" val='60'/>
                    <ProgressBar name="Angular" val='60'/>
                    <ProgressBar name="C/C++" val='60'/>
                    <ProgressBar name="Android Studio" val='55'/>                    
                    <ProgressBar name="After Effect" val='50'/>
                    <ProgressBar name="Unity" val='50'/>
  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from './progressBar.vue'
export default {
    components:{ProgressBar}
}
</script>

<style lang="scss" scoped>
.moreTop{
    top:45px;
}
#skills{
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .container{       
        .flexxed{
            display: flex;
            column-gap: 40px;
            @media screen and (max-width: 930px){
                flex-flow: column;
            }
        }
        .title{
            font-size: 25pt;
            font-weight: 700;
            line-height: 28px;
            text-align: center;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
    }
}
.mtX{
    margin-top: 50px;
}
</style>