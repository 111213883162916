export const messagesIT = {
    leng: 'IT',
    ciao: 'Ciao',
    ChiSono: 'Chi Sono',
    Abilità: 'Competenze',
    Formazione: 'Formazione',
    Lavori: 'Lavori',
    Contatti: 'Contatti',
    Scorri: 'Scorri',
    Chi:'CHI',
    Sono:'SONO.',
    Comp1:'Competenze',
    Inf1: 'Informatiche',
    Altre1:'Altre',
    Comp2:'Competenze',
    STRUTTURA:'STRUTTURA',
    DESCRIZIONE: 'DESCRIZIONE',
    apri:'Apri',
    precedente:'PRECEDENTE',
    prossimo:'PROSSIMO',
    Oggetto:'Oggetto',
    Messaggio:'Messaggio',
    Invia:'Invia',
    f1:'Formazione',
    f2:'Scolastica',
    Ristorazione:'Ristorazione',
    OrgEventi:'Org.Eventi',
    Disegno:'Disegno',
    UniPisa:'Università di Pisa',
    DipInfo:'Dipartimento di Informatica',
    Attuale:'Attuale',
    Valutazione:'Valutazione',
    Istituto:'Istituto Tecnico Economico e Tecnologico',
    IndirizzoSup:'Indirizzo: Sistemi Informativi Aziendali',
    chisono1:'Ciao! Mi chiamo Abramo Maffei ed ho ',
    chisono2:'anni.<br>Fin da quando ricevetti alla giovanissima età di 6 anni il mio primo computer, la passione per questo mondo in tutte le sue sfumature, non ha mai smesso di aumentare.<br>Iniziando con software semplici come GIMP e introducendomi alle basi della progettazione web, ho sempre cercato di ampliare le mie conoscenze sfruttandole per trarne un profitto personale fin da subito.',
};