<template>
    <div id="nav">
        <div class="top">
            <router-link :to="'/'+$t('leng')">
                <div class="logo">
                    <img :src="require('../assets/AMWhite.png')" alt="">
                </div>
            </router-link>
            <div class="lenguage">
                <div :class="$t('leng')=='IT'?'lengItem active':'lengItem'" @click="toIt()">IT</div>
                <div :class="$t('leng')=='EN'?'lengItem active':'lengItem'" @click="toEng()">EN</div>
            </div>    
        </div>
        
        <div class="menu">
            <router-link :to="'/'+$t('leng')+'/chi-sono'">
                <div class="menuI">
                    <div class="txt">{{$t('ChiSono')}}</div>
                    <about-icon />
                </div>
            </router-link>
            <router-link :to="'/'+$t('leng')+'/competenze'">
                <div class="menuI">
                    <div class="txt">{{$t('Abilità')}}</div>
                    <code-icon />
                </div>
            </router-link>
            <router-link :to="'/'+$t('leng')+'/formazione'">
                <div class="menuI">
                    <div class="txt">{{$t('Formazione')}}</div>
                    <education-icon />
                </div>
            </router-link>
            <router-link :to="'/'+$t('leng')+'/lavori'">
                <div class="menuI">
                    <div class="txt">{{$t('Lavori')}}</div>
                    <work-icon />
                </div>
            </router-link>
            <router-link :to="'/'+$t('leng')+'/contatti'">
                <div class="menuI">
                    <div class="txt">{{$t('Contatti')}}</div>
                    <info-icon />
                </div>
            </router-link>
        </div>
        <div class="socials">
            <a href="https://www.facebook.com/abramo.maffei"><div class="socialItem"><img src="../assets/imgs/icons/fb.svg" alt=""></div></a>
            <a href="https://www.instagram.com/abramo_maffei/"><div class="socialItem"><img src="../assets/imgs/icons/ig.svg" alt=""></div></a>
            <a href="https://www.linkedin.com/in/abramo-maffei-515553214/"><div class="socialItem"><img src="../assets/imgs/icons/ld.svg" alt=""></div></a>
        </div>
    </div>
</template>

<script>
import {i18n} from '@/lenguages/i18n';
export default {
    methods:{
        toEng(){
            i18n.locale = 'en';
            this.updateRoute();
        },
        toIt(){
            i18n.locale = 'it';
            this.updateRoute();
        },
        updateRoute(){
            var string = '/';
            string = i18n.locale=='it'?string+'IT':string+'EN';
            string += this.$route.fullPath.substring(3);
            history.pushState(
                {},
                null,
                string
            )
        }
    }
}
</script>

<style lang="scss" scoped>
#nav{
    position: fixed;
    left:0;top:50%;
    transform: translateY(-50%);
    background-color: var(--mainBGDarker);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: var(--app-height);
    text-align: center;
    z-index: 11;
    padding: 5px;
    border-radius: 0px 3px 3px 0px;
    width: var(--navWidth);
    .top{
        .logo{
            img{
                width: 100%;
            }
        }
    }
    .lenguage{
        .active{
            color: white;
            &::before{
                content: "> ";
            }
            &::after{
                content: " <";
            }
        }
        .lengItem{
            font-size: 9pt;
            cursor: pointer;
            border-radius: 3px;
            color: var(--mainTXT);
            font-weight: 600;
            letter-spacing: 1px;
            &:hover{
                transition-duration: 0.2s;
                background: var(--linkColor);
                color: var(--mainBGDarker);
            }
        }
    }
    .menu{
        border-top: 1px solid var(--mainTXT);
        border-bottom: 1px solid var(--mainTXT);
        padding: 5px 0px;
        .menuI{
            height: 40px;
            position: relative;
            transition-duration: 0.1s;
            border-radius: 3px;
            color: var(--mainTXT);
            span{
                position: absolute;
                left:50%;top:50%;
                transform: translateX(-50%) translateY(-40%);
            }
            .txt{
                position: absolute;
                left: calc(var(--navWidth) + 0px);
                top:7px;
                transition-duration: 0.1s;
                background: var(--mainBGDarker);
                color: var(--mainTXT);
                opacity: 0;
                padding: 5px 10px;
                border-radius: 3px;
                font-size: 9pt;
                width: max-content;
                display: none;
            }
            &:hover{
                transition-duration: 0.2s;
                background: var(--linkColor);
                color: var(--mainBGDarker);
                .txt{
                    transition-duration: 0.3s;
                    transition-delay: 0.1s;
                    opacity: 1;
                    display: block;
                }
            }
        }
    }
    .socials{
        .socialItem{
            width: 25px;
            margin: 0 auto;
            img{
                filter: invert(91%) sepia(63%) saturate(31%) hue-rotate(135deg) brightness(114%) contrast(100%);
            }
            &:hover{
                img{
                    filter: invert(100%) sepia(86%) saturate(6112%) hue-rotate(129deg) brightness(105%) contrast(106%);
                }
            }
            
        }
    }
}
</style>