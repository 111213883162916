<template>
    <div id="animatedBG" ref="bg">
    </div>
</template>

<script>
import htmlTags from '../utils/htmlTags.json'
export default {
    data() {
        return {
            strings:htmlTags,
            maxWidth:0,
            maxHeight:0,
        }
    },
    methods:{
        randomIntFromInterval(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min)
        },
        addRandom(name){
            const sizeXletter = 9;
            const lineHeight = 25;
            const maxTilt = 20;
            const maxOpacity = 20;
            const minOpactiy = 10;
            const color1 = 'rgb(236, 63, 63)';
            const color2 = 'var(--linkColor)';

            var widthCell = (name.length+2)*sizeXletter;
            var mainDiv = document.createElement('div');      
        
            var txt = '<xmp><'+name+'></xmp>';
            var width = widthCell +'px';
            var height = lineHeight+'px';
            var left = this.randomIntFromInterval(0,this.maxWidth-widthCell)+'px';
            var top = this.randomIntFromInterval(0,this.maxHeight-lineHeight)+'px';
            var opacity = (this.randomIntFromInterval(minOpactiy,maxOpacity)/100);
            var rotation = this.randomIntFromInterval(-maxTilt,maxTilt)+'deg';
            var color = this.randomIntFromInterval(0,10)>5?color1:color2;

            var newDiv = `
                <div class="floatingText unselectable" 
                    data-text="<${name}>"
                    style="
                        width:${width};
                        height:${height};
                        text-align: center;
                        position:absolute;
                        left:${left};
                        top:${top};
                        opacity: ${opacity};
                        transform: rotate(${rotation});
                        color: ${color};
                        ">${txt}</div>`
            mainDiv.innerHTML = newDiv;
            this.$refs.bg.append(mainDiv);
        }
    },
    mounted(){
        this.maxWidth = this.$refs.bg.clientWidth;
        this.maxHeight = this.$refs.bg.clientHeight;
        console.log(this.maxWidth*this.maxHeight);
        
        //1783111:200=dimscreen:x
        var max = 200*(this.maxWidth*this.maxHeight)/1783111;
        //console.log(max);
        
        for(let x=0;x<max;x++){
            var arrayDim = this.strings.length;
            var current = x % (arrayDim);
            this.addRandom(this.strings[current]);
        }
    }
}
</script>

<style lang="scss" scoped>
#animatedBG{
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    filter: blur(0px);
}

.floatingText{
    font-size: 30pt;
    &::after{
        content: 'ciao';
    }
}



</style>