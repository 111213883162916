export const messagesEN = {
    leng: 'EN',
    ciao: 'Hi',
    ChiSono: 'About',
    Abilità: 'Skills',
    Formazione: 'Education',
    Lavori: 'Works',
    Contatti: 'Contacts',
    Scorri: 'Scroll',
    Chi:'ABOUT',
    Sono:'ME.',
    Comp1:'IT',
    Inf1: 'Skills',
    Altre1:'Other',
    Comp2:'Skills',
    STRUTTURA:'STRUCTURE',
    DESCRIZIONE: 'DESCRIPTION',
    apri:'Open',
    precedente:'PREC',
    prossimo:'NEXT',
    Oggetto:'Subject',
    Messaggio:'Message',
    Invia:'Send',
    f1:'School',
    f2:'Education',
    Ristorazione:'Catering',
    OrgEventi:'Event Planning',
    Disegno:'Drawing',
    UniPisa:'University of Pisa',
    DipInfo:'Department of Computer Science',
    Attuale:'Current',
    Valutazione:'Rating',
    Istituto:'Economic and Technological Technical Institute',
    IndirizzoSup:'Course: Corporate Information Systems',
    chisono1:'Hi! My name is Abramo Maffei and i am ',
    chisono2:'years old.<br>Ever since I received my first computer at the very young age of 6, the passion for this world in all its nuances has never stopped growing.<br>Starting with simple software like GIMP and introducing myself to the basics of web design, I have always tried to broaden my knowledge by exploiting it to make a personal profit right away.',
};
