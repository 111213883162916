<template>
    <div id="allWorks">
        <div class="works">
            <Work v-for="(work,index) in works" :key="index" :work="work" :nome="index" :iSrc="require('../assets/works'+work.mainFoto)" />   
        </div>
        <div class="contatti">
            <Contatti/>    
        </div>
        
    </div>
</template>

<script>
import works from '../utils/works.json'
import Work from '../components/Work.vue'
import Contatti from '../components/Contatti.vue'
export default {
    components:{Work,Contatti},
    data() {
        return {
            works:works,
        }
    },
}
</script>

<style lang="scss" scoped>
#allWorks{
    padding-left: var(--navWidth);
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.works{
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.contatti{
    width: 100%;
}
</style>