<template>
    <div class="main">
        <div class="mainC" v-if="work">
            <div class="container" >
                <div class="left" v-if="work.siteFoto" :sezName="$t('apri')">
                    <div class="img">
                        <a :href="work.link"><img v-if="work.siteFoto"  :src="require('../assets/works'+work.siteFoto)" alt=""></a>     
                    </div>
                </div>
                <div class="right">
                    <div class="t">
                        <div class="titolo">{{work.nome}}</div>
                        <a :href="work.link">{{work.link}}</a>   
                    </div>
                    <div class="tags">
                        <div class="tag" v-for="(tag,index) in work.tags" :key="index">{{tag}}</div>
                    </div>
                    <div class="struttura" v-if="work.struttura" v-html="work.struttura" :sezName="$t('STRUTTURA')"></div>
                    <div class="descrizione" 
                        v-html="$t('leng')=='EN'?work.descrizioneEN:work.descrizione" 
                        :sezName="$t('DESCRIZIONE')"></div>
                    <div class="dataCreazione">{{$t('leng')=='EN'?work.dataCreazioneEN:work.dataCreazione}}</div>
                </div>
            </div>
            <div class="fotos" v-if="work.photos">
                <div class="img" v-for="img in work.photos" :key="img">
                    <img :src="require('../assets/works'+img)" alt="">
                </div>
            </div>
            
            <div class="buttons">
                <div :class="prec?'prec':'prec disabled'" @click="linkto(prec)"> &#60;&ndash; {{$t('precedente')}}</div>
                <div :class="next?'next':'next disabled'" @click="linkto(next)">{{$t('prossimo')}} &ndash;></div>
            </div>
        </div>
        <Contatti/>
    </div>
</template>

<script>
import {i18n} from '@/lenguages/i18n';
import works from '../utils/works.json'
import Contatti from '../components/Contatti.vue'
export default {
    components:{Contatti},
    data() {
        return {
            work:{},
            works:works,
            prec:null,
            next:'',
        }
    },
    methods: {
        linkto(nome){
            if(!nome){return;}
            if(i18n.locale=='it'){
                this.$router.push('/IT/lavoro/'+nome).catch(() =>{});    
            }else{
                this.$router.push('/EN/lavoro/'+nome).catch(() =>{}); 
            }            
        },
        setup(){
            var currentIndex = Object.keys(this.works).findIndex(e => e==this.$route.params.id);
            var next = Object.keys(this.works)[currentIndex+1];
            this.prec = Object.keys(this.works)[currentIndex-1];
            this.next = next;
        }
    },
    watch: {
        $route() {
            window.scrollTo({ top: 0, behavior: 'smooth' }); 
            var name = this.$route.params.id;
            this.work = works[name];
            this.setup();
        }
    },
    mounted() {
        window.scrollTo({ top: 0 }); 
        var name = this.$route.params.id;
        this.work = works[name];
        this.setup();

    },
}
</script>

<style lang="scss" scoped>
.main{
    padding-left: var(--navWidth);
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 30px;
}
.mainC{
    max-width: 1200px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin: 0 auto;
    padding: 10px 10px;
}
.container{
    display: flex;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 950px){
        flex-flow: column-reverse;
        row-gap: 20px;
        align-items: center;
    }
    .left{
        flex: 1;
        position: relative;
        
        .img{
           max-height: 740px;
           overflow: auto;
           padding-right: 10px;
           border-radius: 4px;
        }
        
        &::before{
            opacity: 0;
            content: 'APRI';
            content: attr(sezName);
            text-transform: uppercase;
            position: absolute;
            top:-22px;right:-35px;
            transform: rotate(45deg);
            background: var(--mainTXT);
            color: var(--mainBG);
            font-weight: 700;
            font-size: 9pt;
            border-radius: 30px;
            padding: 2px 8px;
            transition-duration: 0.5s;
        }
        &:hover{
            &::before{
                opacity: 1;
                transition-duration: 1s;

            }
        }
        img{
            width: 100%;
            border-radius: 4px;
            opacity: 0.8;
            transition-duration: 0.5s;
            &:hover{
                opacity: 1;
                transition-duration: 1s;
            }
            @media screen and (max-width: 950px){
                max-width: 500px;
            }
        }
    }
    .right{
        flex: 1;
        color: var(--mainTXT);
        .t{
            width: 100%;
            max-width: max-content;
            margin: 0 auto;
            .titolo{
                font-size: 30pt;
                line-height: 35px;
                font-weight: 600;
                overflow: hidden;
                text-align: right;
            }
            a{
                float: right;
                font-size: 9pt;
            }    
        }
        
        .tags{
            display: flex;
            margin-top: 25px;
            column-gap: 10px;
            row-gap: 10px;
            justify-content: center;
            flex-wrap: wrap;
            .tag{
                text-transform: uppercase;
                font-size: 10pt;
                background-color: var(--mainTXT);
                color: var(--mainBG);
                font-weight: 700;
                border-radius: 5px;
                padding: 0px 10px;
            }
        }
        .struttura{
            border-left: 2px solid var(--mainTXT);
            padding-left: 10px;
            margin-left: 10px;
            margin-top: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            position: relative;
            min-height: 70px;
            display: flex;
            align-content: center;
            font-weight: 300;
            &::before{
                content: 'STRUTTURA';
                content: attr(sezName);
                position: absolute;
                font-size: 8pt;
                transform: rotate(-90deg);
                left:-38px; top:22px;
                
            }
        }
        .descrizione{
            border-left: 2px solid var(--mainTXT);
            padding-left: 10px;
            margin-left: 10px;
            margin-top: 40px;
            position: relative;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: justify;
            text-justify: inter-word;
            min-height: 100px;
            display: flex;
            align-content: center;
            font-weight: 300;
            &::before{
                content: 'DESCRIZIONE';
                content: attr(sezName);
                position: absolute;
                font-size: 8pt;
                transform: rotate(-90deg);
                left:-42px; top:27px;
            }
        }

        .dataCreazione{
            text-align: right;
            font-style: italic;
            font-weight: 300;
            font-size: 9pt;
            margin-top: 30px;
        }
    }
    
}
.fotos{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-top: 15px;
        column-gap: 7px;
        max-height: 500px;
        overflow-y: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        .img{
            max-width: calc(33.33% - 14px);
            
            img{
                width: 100%;
            }
        }
    }

.buttons{
    display: flex;
    align-self:stretch;
    justify-content: space-between;
    margin-top: 30px;
    a{
        text-decoration: none;
        font-size: 12pt;
    }
    .prec{
        color: var(--linkColor);
        padding: 0px 10px;
        margin-left: 5px;
        font-size: 9pt;
        cursor: pointer;
        &:hover{
            color: var(--linkColorHover);
            border-color: var(--linkColorHover);
        }
    }
    .next{
        color: var(--linkColor);
        padding: 0px 10px;
        margin-left: 5px;
        font-size: 9pt;
        cursor: pointer;
        &:hover{
            color: var(--linkColorHover);
            border-color: var(--linkColorHover);
        }
    }
    .disabled{
        color: grey;
        cursor: auto;
        &:hover{
            color: grey;
        }
    }
}
</style>